import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';
import { Helmet } from 'react-helmet';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import Footer from './components/navigation/Footer';
import MagicWikiSocket from './components/pages/MagicWiki/MagicWikiSocket';
import NotFoundPage from './components/pages/NotFoundPage';
import { AuthProvider } from './shared/context/AuthContext';
import { InterfaceParametersProvider } from './shared/context/InterfaceParametersContext';
import { SearchBarProvider } from './shared/context/SearchBarContext';
import { HOME_ROUTE, PAGE_DESCRIPTION } from './shared/Constants';
import Theme from './shared/Theme';

const AppMagicWiki = () => {
  return (
    <>
      <ThemeProvider theme={Theme}>
        {/* Dynamically load metadata for HTML header */}
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="description" content={PAGE_DESCRIPTION} />
          <link rel="icon" href="favicon.png" />
          <style>{`body { background-color: ${Theme.backgroundSoftWhite}; }`}</style>
        </Helmet>
        <Router>
          <AppContainer>
            <AuthProvider>
              <InterfaceParametersProvider>
                <SearchBarProvider>
                  <Switch>
                    <Route exact path={HOME_ROUTE}>
                      <MagicWikiSocket title="MagicWiki" simpleSearchBar={true} />
                    </Route>
                    <Route>
                      <NotFoundPage />
                    </Route>
                  </Switch>
                  <Footer title="MagicWiki" />
                </SearchBarProvider>
              </InterfaceParametersProvider>
            </AuthProvider>
          </AppContainer>
        </Router>
      </ThemeProvider>
      <ToastContainer />
    </>
  );
};

export default AppMagicWiki;

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;
